import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import MovingElement from "@elements/MovingElement"

const Section = styled.section`
  ${tw`relative z-10 py-10 mx-auto pb-c96 px-offset 2xl:px-24 mb-c96`}
  max-width: 1600px;
`
const SectionWrap = styled.div`
  ${tw`relative grid grid-cols-2`}
`
const Column = styled.div`
  ${tw`relative z-10 flex flex-col`}

  & .single-image {
    ${tw`relative overflow-visible`}
  }

  &.first-column {
    ${tw`mt-16`}
    & > :nth-of-type(1) {
      ${tw`ml-auto top-20 -right-8`}
    }
    & > :nth-of-type(2) {
      ${tw`z-10 -top-8 -left-0`}
    }
    & > :nth-of-type(3) {
      ${tw`z-0 pr-10 ml-auto -top-20`}
    }
  }
  &.second-column {
    & > :nth-of-type(1) {
      ${tw`ml-auto -top-10`}
    }
    & > :nth-of-type(2) {
      ${tw`top-10 left-20`}
    }
    & > :nth-of-type(3) {
      ${tw`top-10 `}
    }
  }
`
const ImageWrap = styled.div`
  ${tw`relative z-10`}
`

const WpAcfGalleryRefBlock = ({ moduleData }) => {
  const blockData = moduleData.acfRefGalleryBlock
  const sectionAttributes = moduleData.attributes
  const sectionId = sectionAttributes.anchor || ""

  const firstColumn = blockData.images.filter(
    (singleImage, index) => index % 2 !== 0
  )
  const secondColumn = blockData.images.filter(
    (singleImage, index) => index % 2 === 0
  )

  return (
    <Section id={sectionId}>
      <SectionWrap>
        <Column className="first-column">
          {firstColumn.map((singleImage, i) => {
            const image = getImage(singleImage.localFile)
            return (
              <ImageWrap className="single-image" key={`gallery-${i}`}>
                <MovingElement
                  speed={i % 2 === 0 ? 0.04 : 0.07}
                  direction={{
                    negative: i % 2 === 0 ? "-" : "+",
                    axis: "y",
                  }}
                  content={false}
                  className="transition-all duration-700 ease-in-out"
                >
                  <GatsbyImage
                    objectFit="contain"
                    className=""
                    image={image}
                    alt={singleImage?.altText}
                  />
                </MovingElement>
              </ImageWrap>
            )
          })}
        </Column>
        <Column className="second-column">
          {secondColumn.map((singleImage, i) => {
            const image = getImage(singleImage.localFile)
            return (
              <ImageWrap className="single-image" key={`gallery-${i}`}>
                <MovingElement
                  speed={i % 2 !== 0 ? 0.04 : 0.07}
                  direction={{
                    negative: i % 2 !== 0 ? "-" : "+",
                    axis: "y",
                  }}
                  content={false}
                  className="transition-all duration-1000 ease-in-out"
                >
                  <GatsbyImage
                    objectFit="contain"
                    className=""
                    image={image}
                    alt={singleImage?.altText}
                  />
                </MovingElement>
              </ImageWrap>
            )
          })}
        </Column>
      </SectionWrap>
    </Section>
  )
}

WpAcfGalleryRefBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfGalleryRefBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfGalleryRefBlock
